<style lang="scss" scoped>
/deep/ .comp-exchangestock-detail {
    margin-top: 6vh !important;
    max-width: 1200px;
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
.assets {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
    }
    .asset-title {
        vertical-align: middle;
    }
}
</style>

<template>
    <div>
        <el-dialog title="更换详情" custom-class="c-el-dialog comp-exchangestock-detail" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" append-to-body>
            <el-form :model="dialogData" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="仓库">
                            <el-input :value="dialogData.warehouse && dialogData.warehouse.name" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="状态">
                            <el-input :value="tranceStatus(dialogData.status)" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="使用者">
                            <el-input :value="dialogData.assetUser && dialogData.assetUser.name" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="负责人">
                            <el-input :value="dialogData.principal" readonly></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="更换的新资产" prop="items">
                            <el-table :data="dialogData.items" border fit highlight-current-row size="small">
                                <el-table-column prop="asset.name" label="图片【分类-条形码】资产名称" min-width="160">
                                    <template slot-scope="scope" v-if="scope.row.asset">
                                        <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                                            :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                                            :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                                            <div slot="error">
                                                <i class="el-icon-picture-outline error-image"></i>
                                            </div>
                                        </el-image>
                                        <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                                        <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="serialNumbers" label="序列号" width="360">
                                    <template slot-scope="scope">
                                        <el-tag class="mrgr5 mrgb5" type="info" size="small" v-for="sn in scope.row.serialNumbers" :key="sn">{{ sn }}</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="count" label="数量" width="100"></el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="回收的旧资产" prop="items" v-if="dialogData.returnItems && dialogData.returnItems.length > 0">
                            <el-table :data="dialogData.returnItems" border fit highlight-current-row size="small">
                                <el-table-column prop="asset.name" label="图片【分类-条形码】资产名称" min-width="160">
                                    <template slot-scope="scope" v-if="scope.row.asset">
                                        <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                                            :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                                            :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                                            <div slot="error">
                                                <i class="el-icon-picture-outline error-image"></i>
                                            </div>
                                        </el-image>
                                        <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                                        <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="serialNumbers" label="序列号" width="360">
                                    <template slot-scope="scope">
                                        <el-tag class="mrgr5 mrgb5" type="info" size="small" v-for="sn in scope.row.serialNumbers" :key="sn">{{ sn }}</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="count" label="数量" width="100"></el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" v-if="dialogData.attachments && dialogData.attachments.length > 0">
                        <el-form-item label="附件">
                            <show-image :imageUrls="dialogData.attachments" :justQuery="true"></show-image>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" :rows="5" :value="dialogData.remark" readonly placeholder="暂无备注"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="创建时间">
                            <el-input :value="dialogData.creationTime" readonly></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import ShowImage from "@/components/ImageMultipleUpload"
export default {
    name: "compExchangeStockDetail",
    mixins: [enumConfigs],
    components: { ShowImage },
    data() {
        return {
            showDialog: false,
            dialogData: {},
            formRefName: "refExchangeStockDetail"
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(row))
            this.dialogData.creationTime = this.getFormatDate(this.dialogData.creationTime)
            this.showDialog = true
        },
        tranceStatus(status) {
            let back = ""
            try {
                let item = this.arrStockBillStatus.find(x => x.value === status)
                back = item && item.desc
            } catch(ex) {
                back = ""
            }
            return back
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>